import React, { useEffect, useState, useRef, useContext, Suspense, forwardRef, useImperativeHandle, useMemo } from 'react'
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Row, Col, Card, Badge, ListGroup } from 'react-bootstrap';

import { Modal, Select, Tooltip, Form, Input, Checkbox, Carousel, Dropdown, Space } from 'antd';
import { format as formatDate } from 'date-fns';
import moment from 'moment';

import InputMask from 'react-input-mask';
import { FileDrop } from 'react-file-drop';

import { FaPlusCircle, FaExclamation } from "react-icons/fa";

import './UserSetting.css';
import { ASActiveDateRange, ASDateField } from '../Common/DateRangePicker';
import { LoadingMask, ShowAlertMessage, ClearASImageUserCache, NoDataMask, enumNumberCodeType, NumberCodeField, ASImage } from '../../modules/Common/Common.js';
import { EnumASCloudAPIType, EnumActionType, EnumAccountType, EnumCodeFormat, EnumErrorCode, EnumCardStatus, EnumMajorFactor } from '../ASUtils/ASConfig';
import { useASConfig } from '../ASUtils/ASUtils';
import { AppContext, Constants, CopyObject, TrimFilterParams } from '../../Utils';
import { getJpegBase64String } from '../UserList/ImportPhoto';

import { OrganizationFields } from './Organization';
import SVGIcon from '../../icons.js';

const AccessRuleModal = React.lazy(
    () => import("../AccessRule/AccessRule").then(module => ({default: module.AccessRuleModal}))
);
const EditPhoto = React.lazy(
    () => import("../UserList/ImportPhoto").then(module => ({default: module.EditPhoto}))
);

const writePriv = 'w';

export const defaultUserData = {
    u_user_id: 0,
    u_first_name: '',
    u_last_name: '',
    u_name: '',
    u_email: '',
    u_phone: '',
    u_id: '',
    u_og1: '',
    u_og1_id: 0,
    u_og2: '',
    u_og2_id: 0,
    u_og3: '',
    u_og3_id: 0,
    u_title: '',
    u_memo: '',
    u_photo: '',
    u_photo_s: ''
};

export const defaultAccessObjData = {
    ao_id: -1,
    ao_m_factor: EnumMajorFactor.Card,

    ao_type: 0,
    ao_active: EnumCardStatus.Active,
    ao_start_time: '',
    ao_end_time: '',
    ar_ids: [],

    c_no: '',
    c_code: EnumCodeFormat.Wiegand26.code_value,
    c_pin_code: '',
    c_passcode: '',

    v_no: '',
    v_classification: '',
    v_color: '',
    v_settings: '',

    face: '',
    pwd: ''   // Password for Sub Factor
}

export const enumUserFieldsMaxLength = {
    u_first_name: 32,
    u_last_name: 32,
    u_name: 65,
    u_email: 64,
    u_phone: 20,
    u_id: 16,
    u_og1: 32,
    u_og2: 32,
    u_og3: 32,
    u_title: 16
};

export const enumAccesskeyFieldsMaxLength = {
    v_no: 20,
    v_classification: 32,
    v_color: 20,
    face: 64
}

const UserSettingForm = forwardRef(({fields, disabled, ...props}, ref) => {
    const intl = useIntl();
    const { getOrganizationMap, organizationMap, organizationTitles } = useContext(AppContext);
    const [form] = Form.useForm();
    const orgFieldsRef = useRef();
    const u_name = Form.useWatch('u_name', form);
    
    const [userPhoto, setUserPhoto] = useState({
        imgSrc: null,
        imgSrc_s: null,
        action_type: EnumActionType.None
    });

    const validateMessages = {
        required: intl.formatMessage({id: 'required_field'}),
        string: {
            /* eslint-disable no-template-curly-in-string */
            max: intl.formatMessage({id: 'maximum_field_length'}, {'0': '${max}'})
        }
    };

    useEffect(() => {
        getOrganizationMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        form.setFieldsValue(fields);
        setUserPhoto({
            imgSrc: fields['u_photo'] || null,
            imgSrc_s: fields['u_photo_s'] || null,
            action_type: EnumActionType.None
        });
    }, [form, fields]);

    useEffect(() => {
        var ogNames = {
            u_og1: '',
            u_og2: '',
            u_og3: ''
        };
        var level1 = organizationMap.find(item => item.og_id === fields.u_og1_id);
        if (level1) {
            ogNames.u_og1 = level1.og_name;
            var level2 = level1.children.find(item => item.og_id === fields.u_og2_id);
            if (level2) {
                ogNames.u_og2 = level2.og_name;
                var level3 = level2.children.find(item => item.og_id === fields.u_og3_id);
                if (level3) {
                    ogNames.u_og3 = level3.og_name;
                }
            }
        }
        form.setFieldsValue(ogNames);
    }, [form, fields, organizationMap]);

    const renderField = (msgKey, params) => {
        return (
            <FormattedMessage id={msgKey}>
            {
                (msg) => (
                    <Form.Item label={msg} {...params}>
                        <Input allowClear={true} />
                    </Form.Item>
                )
            }
            </FormattedMessage>
        );
    };

    const handleValuesChange = (changedValues, allValues) => {
        if (typeof(changedValues['u_first_name']) !== 'undefined' || typeof(changedValues['u_last_name']) !== 'undefined') {
            var strDisplayName = '';
            if (allValues.u_first_name && allValues.u_last_name) {
                strDisplayName = `${allValues.u_first_name} ${allValues.u_last_name}`;
            } else {
                strDisplayName = allValues.u_first_name || allValues.u_last_name;
            }
            form.setFieldValue('u_name', strDisplayName);
        }
    };

    const handlePhotoChange = (strBase64, strBase64_s) => {
        setUserPhoto({imgSrc: strBase64, imgSrc_s: strBase64_s, action_type: strBase64 ? EnumActionType.Add : EnumActionType.Delete});
    };

    useImperativeHandle(ref, () => ({
        validateFields(callback) {
            callback = callback || function() {};

            form.validateFields()
            .then((values) => {
                var params = {
                    ...fields,
                    ...TrimFilterParams(values),
                    ...orgFieldsRef.current.getOrganizationIds()
                };

                if (userPhoto.action_type !== EnumActionType.None) {
                    params['u_photo_action_type'] = userPhoto.action_type;

                    if (userPhoto.action_type === EnumActionType.Delete) {
                        params['u_photo'] = '';
                        params['u_photo_s'] = '';
                    } else {
                        if (userPhoto.imgSrc && userPhoto.imgSrc_s) {
                            params['u_photo'] = userPhoto.imgSrc.replace('data:image/jpeg;base64,', '');
                            params['u_photo_s'] = userPhoto.imgSrc_s.replace('data:image/jpeg;base64,', '');
                        } else {
                            params['u_photo_action_type'] = EnumActionType.None;
                        }
                    }
                }

                callback(true, params);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
                if (Array.isArray(info.errorFields) && info.errorFields.length > 0) {
                    var field = form.getFieldInstance(info.errorFields[0].name[0]);
                    if (field) field.focus();
                }
                callback(false);
            });
        }
    }));

    return (
        <div className='usersetting-fields-panel'>
            <div className='usersetting-photo-upload-panel'>
                <UserPhotoUpload u_user_id={fields.u_user_id} imgSrc={userPhoto.imgSrc} disabled={disabled} onChange={handlePhotoChange} />
                <span>{u_name}</span>
            </div>

            <Form form={form} className='usersetting-fields' layout="vertical" autoComplete="off" validateMessages={validateMessages} disabled={disabled} onValuesChange={handleValuesChange}>
                <Row>
                    <Col md="4">
                        {renderField('first_name', {name: 'u_first_name', rules: [{type: 'string', max: enumUserFieldsMaxLength.u_first_name}]})}
                    </Col>
                    <Col md="4">
                        {renderField('last_name', {name: 'u_last_name', rules: [{type: 'string', max: enumUserFieldsMaxLength.u_last_name}]})}
                    </Col>
                    <Col md="4">
                        {renderField('display_name', {name: 'u_name', rules: [{required: true}, {type: 'string', max: enumUserFieldsMaxLength.u_name}]})}
                    </Col>
                </Row>

                <Row>
                    <Col md="4">
                        {renderField('id', {name: 'u_id', rules: [{type: 'string', max: enumUserFieldsMaxLength.u_id}]})}
                    </Col>
                    <Col md="4">
                        {renderField('email', {name: 'u_email', rules: [{type: 'string', max: enumUserFieldsMaxLength.u_email}]})}
                    </Col>
                    <Col md="4">
                        {renderField('phone', {name: 'u_phone', rules: [{type: 'string', max: enumUserFieldsMaxLength.u_phone}]})}
                    </Col>
                </Row>

                <OrganizationFields ref={orgFieldsRef} og_titles={organizationTitles} og_map={organizationMap} />

                <Row>
                    <Col md="4">
                        {renderField('job_title', {name: 'u_title', rules: [{type: 'string', max: enumUserFieldsMaxLength.u_title}]})}
                    </Col>
                    <Col md="8">
                        {renderField('memo', {name: 'u_memo'})}
                    </Col>
                </Row>
            </Form>
        </div>
    );
});
UserSettingForm.defaultProps = {
    fields: {},
    disabled: false
};

export default class UserSettingModal extends React.Component {
    static defaultUserData = {
        ...defaultUserData,
        readOnly: false,
        deletable: true
    };
    static defaultAccessObjData = {
        ...defaultAccessObjData,
        c_enable: false,
        v_enable: false,
        f_enable: false,
        pin_enable: false,
        pwd_enable: false,
        readOnly: false,
        deletable: true,
        invalid: false,
        deleted: false,
    };
    
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            fields: {...UserSettingModal.defaultUserData},
            activeTab: -1,
            carouselSpeed: 0,
            carouselAniming: false,
            accessObjs: [],
            cards: [],
            vehicles: [],
            openModal: true,
            showAdvanced: !!window.localStorage.getItem(Constants.storageNames.showUserAdvanced),
            showLoading: false,
            alertMsg: {
                msg: '',
                type: '',
                show: false,
                onClose: null
            }
        };

        this.mainRef = React.createRef();
        this.userSettingFormRef = React.createRef();
        this.carouselRef = React.createRef();
        this.allAccessObjRef = {};

        this.handleTabAdd = this.handleTabAdd.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTabDelete = this.handleTabDelete.bind(this);
        this.handleAccessObjChange = this.handleAccessObjChange.bind(this);

        this.handleOk = this.handleOk.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    componentDidMount() {
        this.setState({fields: CopyObject(UserSettingModal.defaultUserData), accessObjs: []});

        const addNewAccessKey = () => {
            if (!this.props.fromLog) return;

            const { m_factor, c_code, c_no, v_no } = this.props.fromLog;
            var newAccessKey = {...UserSettingModal.defaultAccessObjData, ao_m_factor: m_factor};

            while (c_no && c_code) {
                const codeForamt = Object.values(EnumCodeFormat).find(item => item.code_value === c_code);
                if (!codeForamt) break;
                const len = codeForamt.code_A_strlen + codeForamt.code_B_strlen + (codeForamt.code_A_strlen > 0 && codeForamt.code_B_strlen > 0 ? 1 : 0);
                if (len !== c_no.length) break;

                newAccessKey = {
                    ...newAccessKey,
                    c_enable: true,
                    c_code, c_no
                }
                break;
            }

            if (v_no) {
                newAccessKey = {
                    ...newAccessKey,
                    v_enable: true,
                    v_no
                }
            }

            var accessObjs = [...this.state.accessObjs],
                index = -1;

            if (m_factor === EnumMajorFactor.Card) {
                newAccessKey.c_enable = true;
                index = accessObjs.findIndex(item => item.ao_m_factor === m_factor && item.c_code === c_code && (item.c_no === c_no || c_code === EnumCodeFormat.Passcode.code_value));
            }
            else if (m_factor === EnumMajorFactor.Vehicle) {
                newAccessKey.v_enable = true;
                index = accessObjs.findIndex(item => item.ao_m_factor === m_factor && item.v_no === v_no);
            }
            else if (m_factor === EnumMajorFactor.Face) {
                newAccessKey.f_enable = true;
                index = accessObjs.findIndex(item => item.ao_m_factor === m_factor);
            }

            if (index  > -1) {
                setTimeout(() => {
                    this.setState({activeTab: index, carouselSpeed: 0, showAdvanced: true});
                }, 300);
            } else {
                if (accessObjs.length < Constants.maxCardVehicleCount) {
                    accessObjs.push(newAccessKey);
                    this.setState({
                        accessObjs
                    }, () => {
                        setTimeout(() => {
                            this.setState({activeTab: accessObjs.length - 1, carouselSpeed: 0, showAdvanced: true});    
                        }, 300);
                    });
                } else {
                    this.setState({
                        alertMsg: {
                            ...this.state.alertMsg,
                            type: 'error',
                            msg: <FormattedMessage id='err_msg.56' />,
                            show: true,
                            onClose: () => {
                                this.setState({
                                    alertMsg: {
                                        ...this.state.alertMsg,
                                        onClose: null,
                                        show: false
                                    }
                                });
                            }
                        }
                    });
                }
            }
        };

        if (this.props.u_user_id > 0) {
            this.setState({showLoading: true});

            this.context.getAccessRuleList(false, () => {
                this.context.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.USER, action_type: EnumActionType.Query, u_user_id: this.props.u_user_id}, (resp) => {
                    if (!resp.errcode && Array.isArray(resp.data) && resp.data.length > 0) {
                        var fields = {...this.state.fields};
                        Object.keys(fields).forEach(key => {
                            if (typeof(resp.data[0][key]) !== 'undefined' && resp.data[0][key] !== null) {
                                fields[key] = resp.data[0][key];
                            }
                        });
    
                        var accessObjs = [];
                        if (Array.isArray(resp.data[0]['u_aos']) && resp.data[0]['u_aos'].length > 0) {
                            resp.data[0]['u_aos'].forEach(item => {
                                var accessObj = {
                                    ...UserSettingModal.defaultAccessObjData,
                                    ...item
                                };
                                if (accessObj.c_start_time) { accessObj.ao_start_time = formatDate(new Date(accessObj.c_start_time), 'yyyy-MM-dd HH:mm') }
                                if (accessObj.c_end_time) { accessObj.ao_end_time = formatDate(new Date(accessObj.c_end_time), 'yyyy-MM-dd HH:mm') }
                                accessObj.c_enable = !!(accessObj.c_no || accessObj.c_passcode);
                                accessObj.v_enable = !!accessObj.v_no;
                                accessObj.f_enable = !!accessObj.face;
                                accessObj.pin_enable = !!accessObj.c_pin_code;
                                accessObj.pwd_enable = !!accessObj.pwd;
                                accessObj.invalid = accessObj.ar_ids.length === 0;
                                accessObj.readOnly = this.context.accountInfo.type !== EnumAccountType.admin && !accessObj.ar_ids.some(ar_id => this.context.accessRuleList.some(item => item.ar_id === ar_id && item.privilege.includes(writePriv)));
                                accessObj.deletable = this.context.accountInfo.type === EnumAccountType.admin || accessObj.ar_ids.every(ar_id => this.context.accessRuleList.some(item => item.ar_id === ar_id && item.privilege.includes(writePriv)));
                                accessObjs.push(accessObj);
                            });
                        }

                        fields.readOnly = this.context.accountInfo.type !== EnumAccountType.admin && accessObjs.every(item => item.readOnly);
                        fields.deletable = this.context.accountInfo.type === EnumAccountType.admin || accessObjs.every(item => item.deletable);
    
                        this.setState({fields, accessObjs}, addNewAccessKey);
    
                    } else if (resp.errmsg) {
                        this.setState({
                            alertMsg: {
                                ...this.state.alertMsg,
                                type: 'error',
                                msg: resp.errmsg,
                                show: true
                            }
                        });
                    }
                    this.setState({showLoading: false});
                });
            });
        } else {
            addNewAccessKey();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeTab !== this.state.activeTab) {
            this.carouselRef.current.goTo(this.state.activeTab);
            setTimeout(() => {
                this.setState({carouselSpeed: 300});

                // prevent Carousel not active when all accessObjs deleted and create new one
                if (this.state.accessObjs.length === 1 && prevState.activeTab === -1) {
                    this.carouselRef.current.goTo(-1);
                }
            }, this.state.carouselSpeed + 1);
            
            var el = document.querySelector(`.usersetting-cards-item[idx="${this.state.activeTab}"]`);
            if (el) {
                el.scrollIntoView({behavior: "smooth", inline: "center"});
            }
        }
    }

    componentWillUnmount() {
        Object.keys(this.allAccessObjRef).forEach(idx => {
            this.allAccessObjRef[idx] = null;
        });
        this.allAccessObjRef = {};
    }

    validatorCardForm = (focus, callback) => {
        callback = callback || function() {};

        if (this.allAccessObjRef[this.state.activeTab]) {
            this.allAccessObjRef[this.state.activeTab].validateFields(focus, (valid) => {
                if (this.state.activeTab > -1 && this.state.activeTab < this.state.accessObjs.length) {
                    var accessObjs = [...this.state.accessObjs];
                    accessObjs[this.state.activeTab].invalid = !valid;
                    this.setState({accessObjs}, callback);
                } else {
                    callback();
                }
            });
        } else {
            callback();
        }
    };

    handleTabAdd = ({key}) => {
        key = Number(key);
        if (key === EnumMajorFactor.Face && this.state.accessObjs.some(item => item.ao_m_factor === EnumMajorFactor.Face)) {
            this.setState({
                alertMsg: {
                    ...this.state.alertMsg,
                    type: 'error',
                    msg: <FormattedMessage id='err_msg.59' />,
                    show: true,
                    onClose: () => {
                        this.setState({
                            alertMsg: {
                                ...this.state.alertMsg,
                                onClose: null,
                                show: false
                            }
                        });
                    }
                }
            });
            return;
        }

        var accessObj = {
            ...UserSettingModal.defaultAccessObjData,
            ao_m_factor: key
        };
        if (key === EnumMajorFactor.Card) accessObj.c_enable = true;
        else if (key === EnumMajorFactor.Vehicle) accessObj.v_enable = true;
        else if (key === EnumMajorFactor.Face) accessObj.f_enable = true;

        var accessObjs = [
            ...this.state.accessObjs,
            accessObj
        ];
        this.setState({accessObjs, activeTab: accessObjs.length - 1});
    };
    handleTabChange = (idx) => {
        this.validatorCardForm(false, () => {
            this.setState({activeTab: idx});
        });
    };
    handleTabDelete = (e, idx) => {
        if (idx === -1 || idx >= this.state.accessObjs.length) return;
        e.stopPropagation();
        
        var accessObjs = [...this.state.accessObjs];
        accessObjs.splice(idx, 1);

        var activeTab = this.state.activeTab;
       
        if (idx < activeTab) {
            // Prevent slick to prev tab create fresh
            accessObjs.splice(activeTab, 0, {...accessObjs[activeTab - 1]});
            accessObjs[activeTab].deleted = true;
            activeTab--;
            this.setState({accessObjs, carouselSpeed: 0}, () => {
                setTimeout(() => {
                    this.carouselRef.current.goTo(activeTab, true);
                    accessObjs.splice(activeTab + 1, 1);
                    this.setState({accessObjs, activeTab, carouselSpeed: 0});
                }, 300);
            });
        } else {
            if (activeTab === idx) {
                activeTab = accessObjs.length > idx ? idx : idx - 1;
            } else if (idx < activeTab) {
                activeTab--;
            }
            this.setState({accessObjs, activeTab, carouselSpeed: 0});
        }
    };

    handleAccessObjChange = (index, accessObj) => {
        if (index < this.state.accessObjs.length) {
            var accessObjs = [...this.state.accessObjs], errmsg;
            accessObjs[index] = accessObj;
            if (accessObjs.filter(item => item.c_code === EnumCodeFormat.Passcode.code_value).length > Constants.maxPasscodeCount) {
                accessObj.c_code = EnumCodeFormat.Wiegand26.code_value;
                errmsg = <FormattedMessage id='import_err_max_passcode_cards' values={{'0': Constants.maxPasscodeCount}} />;

            } else {
                this.setState({accessObjs});
            }

            if (errmsg) {
                this.setState({
                    accessObjs,
                    alertMsg: {
                        ...this.state.alertMsg,
                        type: 'error',
                        msg: errmsg,
                        show: true,
                        onClose: () => {
                            this.setState({
                                alertMsg: {
                                    ...this.state.alertMsg,
                                    onClose: null,
                                    show: false
                                }
                            });
                        }
                    }
                });
            }
        }
    };

    handleDelete = e => {
        var handleSuccessDelete = () => {
            this.setState({alertMsg: {...this.state.alertMsg, show: false, onClose: null}});
            this.handleClose({
                action_type: EnumActionType.Delete,
                u_user_id: this.props.u_user_id
            });
        };

        var handleConfirmDelete = (bSuccess) => {
            this.setState({alertMsg: {...this.state.alertMsg, show: false, onClose: null}});
            if (bSuccess) {
                this.setState({showLoading: true});
                var audit_memo = {
                    ...this.state.fields,
                    accessObjs: [...this.state.accessObjs]
                };
                this.context.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.USER, action_type: EnumActionType.Delete, u_user_id: this.props.u_user_id, audit_memo}, (resp) => {
                    if (!resp.errcode) {
                        this.setState({
                            alertMsg: {
                                ...this.state.alertMsg,
                                type: 'success',
                                msg: <FormattedMessage id="successfully_delete_format" defaultMessage="Successfully deleted {0} record(s)." values={{0: 1}} />,
                                show: true,
                                onClose: handleSuccessDelete
                            }
                        });
                    } else {
                        this.setState({
                            alertMsg: {
                                ...this.state.alertMsg,
                                type: 'error',
                                msg: resp.errmsg,
                                show: true
                            }
                        });
                    }
                });
            }
        };

        this.setState({
            alertMsg: {
                ...this.state.alertMsg,
                msg: <FormattedMessage id="confirm_delete_msg" defaultMessage="Are you sure you want to delete ''{0}'' ?" values={{0: this.state.fields.u_name}} />,
                type: 'question',
                show: true,
                onClose: handleConfirmDelete
            }
        });
    };

    handleOk = () => {
        if (this.state.accessObjs.length === 0) {
            this.setState({
                alertMsg: {
                    ...this.state.alertMsg,
                    type: 'error',
                    msg: <FormattedMessage id={`err_msg.${EnumErrorCode.ERR_USER_NO_ACCESSOBJECT}`} />,
                    show: true,
                    onClose: () => {
                        this.setState({
                            alertMsg: {
                                ...this.state.alertMsg,
                                onClose: null,
                                show: false
                            }
                        });
                    }
                }
            });
            return;
        }

        var callback = (fields) => {
            const excludeFields = ['c_enable', 'v_enable', 'f_enable', 'pin_enable', 'pwd_enable', 'readOnly', 'deletable', 'invalid'];
            var params = {
                ...fields,
                api_cmd: EnumASCloudAPIType.USER,
                action_type: EnumActionType.Add,
                u_aos: []
            };

            this.state.accessObjs.forEach(item => {
                var ao = {};
                Object.entries(item).forEach(([key, val]) => {
                    if (!excludeFields.includes(key)) ao[key] = val;
                });
                if (!item.c_enable) {
                    ao.c_no = '';
                    ao.c_passcode = '';
                }
                if (!item.v_enable) ao.v_no = '';
                if (item.ao_m_factor !== EnumMajorFactor.Face || !item.pwd_enable) ao.pwd = '';
                ao.c_pin_code = item.pin_enable ? item.c_pin_code : '';
                ao.face = item.ao_m_factor === EnumMajorFactor.Face ? (item.face || 'new') : '';
                params.u_aos.push(ao);
            });
    
            this.setState({showLoading: true});
            this.context.ajaxASCloud(Constants.urls.access, params, (resp) => {
                var bSuccess = false;
                if (!resp.errcode && Array.isArray(resp.data) && resp.data.length > 0) {
                    bSuccess = true;
                    ClearASImageUserCache(resp.data[0].u_user_id);
                }
                const handleCloseError = () => {
                    this.setState({alertMsg: {...this.state.alertMsg, show: false }});
                };
                if (fields.u_photo_action_type === EnumActionType.Add && fields.u_photo) {
                    fields.u_photo = 'data:image/jpeg;base64,' + fields.u_photo;
                    fields.u_photo_s = 'data:image/jpeg;base64,' + fields.u_photo_s;
                }
                this.setState({
                    showLoading: false,
                    alertMsg: {
                        ...this.state.alertMsg,
                        type: bSuccess ? 'success' : 'error',
                        msg: bSuccess ? <FormattedMessage id="saved_successfully" defaultMessage="Saved successfully." /> : resp.errmsg,
                        show: true,
                        onClose: bSuccess ? this.handleCloseAlert : handleCloseError
                    },
                    fields: {
                        ...fields,
                        u_user_id: bSuccess? resp.data[0].u_user_id : this.state.fields.u_user_id
                    }
                });
                
            });
        };
        const validator = () => {
            setTimeout(() => {
                this.validatorCardForm(true);
            }, 100);
        };
        this.userSettingFormRef.current.validateFields((valid, fields) => {
            if (!valid) return;

            this.validatorCardForm(true, () => {
                if (this.state.accessObjs.some(accessObj => accessObj.invalid)) {
                    this.setState({activeTab: this.state.accessObjs.findIndex(accessObj => accessObj.invalid)}, validator);
                } else {
                    callback(fields);
                }
            });
        });
    };

    handleClose = (userData) => {
        this.setState({openModal: false});
        setTimeout(() => {
            this.props.onClose(userData?.u_user_id ? userData : null);
        }, 300);
    };

    handleCloseAlert = () => {
        this.setState({
            alertMsg: {
                ...this.state.alertMsg,
                show: false
            }
        });
        this.context.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.USER, action_type: EnumActionType.Query, u_user_id: this.state.fields.u_user_id}, (resp) => {
            if (!resp.errcode && Array.isArray(resp.data) && resp.data.length > 0) {
                this.handleClose(resp.data[0]);
            } else {
                this.handleClose();
            }
        });
    };

    getContainer = () => {
        return this.mainRef.current;
    };

    handleBeforeCarouselChange = () => {
        clearTimeout(this.carouselTimer);
        this.setState({carouselAniming: true});
        this.carouselTimer = setTimeout(() => {
            this.setState({carouselAniming: false});
        }, 300);
    };
    handleAfterCarouselChange = () => {
        clearTimeout(this.carouselTimer);
        this.setState({carouselAniming: false});
    };

    handleShowAdvancedClick = () => {
        this.setState({showAdvanced: !this.state.showAdvanced}, () => {
            window.localStorage.setItem(Constants.storageNames.showUserAdvanced, this.state.showAdvanced ? 1 : 0);
        });
    };

    render() {
        return (
            <Modal centered width='70%' className='usersetting-modal' maskClosable={false} open={this.state.openModal} onCancel={this.handleClose} getContainer={this.props.getContainer}
                title={
                    <>
                        {this.props.u_user_id <= 0 ? <FormattedMessage id="add_user" defaultMessage="Add User" /> : <FormattedMessage id="edit_user" defaultMessage="Edit User" />}
                        {this.state.fields.readOnly && <Badge bg="secondary" style={{marginLeft: '2em'}}><FormattedMessage id='read_only' /></Badge>}
                    </>
                }
                footer={[
                    <Button key='delete' variant="danger" className='action' disabled={!this.state.fields.deletable} onClick={this.handleDelete} hidden={this.props.u_user_id <= 0}>
                        <FormattedMessage id="delete_the_user" defaultMessage="Delete the User" />
                    </Button>,
                    <div key='space' className='space'></div>,
                    <Button key='cancel' variant="secondary" className='action' onClick={this.handleClose}>
                        <FormattedMessage id="cancel" defaultMessage="Cancel" />
                    </Button>,
                    <Button key='save' className='action' disabled={this.state.fields.readOnly} onClick={this.handleOk}>
                        <FormattedMessage id="save" defaultMessage="Save" />
                    </Button>
                ]}
            >

                <div ref={this.mainRef} className='app-content-main scroll-box' style={{height: this.state.showAdvanced ? '750px' : '705px'}}>
                    <UserSettingForm ref={this.userSettingFormRef} disabled={this.state.fields.readOnly} fields={this.state.fields} />

                    <Card className='usersetting-cards-panel'>
                        <Card.Header>
                            <div className='usersetting-cards'>
                                <div className='usersetting-cards-add'>
                                    <Dropdown arrow={true} placement='bottom'
                                        disabled={this.state.accessObjs.length >= Constants.maxCardVehicleCount || this.state.fields.readOnly}
                                        trigger={!process.env.REACT_APP_ENABLE_VEHICLE && !process.env.REACT_APP_ENABLE_FACE ? [] : ['hover']}
                                        menu={{onClick: this.handleTabAdd, items: [
                                            {key: EnumMajorFactor.Card, label: this.context.useASConfig('getFactorName', EnumMajorFactor.Card)}
                                        ].concat(
                                            process.env.REACT_APP_ENABLE_VEHICLE ? [{key: EnumMajorFactor.Vehicle, label: this.context.useASConfig('getFactorName', EnumMajorFactor.Vehicle)}] : []
                                        ).concat(
                                            process.env.REACT_APP_ENABLE_FACE ? [{
                                                key: EnumMajorFactor.Face, label: this.context.useASConfig('getFactorName', EnumMajorFactor.Face), 
                                                disabled: this.state.accessObjs.some(item => item.ao_m_factor === EnumMajorFactor.Face)
                                            }] : []
                                        )}}
                                    >
                                        <Button className='action'
                                            onClick={() => {if(!process.env.REACT_APP_ENABLE_VEHICLE && !process.env.REACT_APP_ENABLE_FACE) this.handleTabAdd({key: EnumMajorFactor.Card})}}
                                        >
                                            <SVGIcon.AccessKeyAdd />
                                        </Button>
                                    </Dropdown>
                                </div>
                                {
                                    this.state.accessObjs.filter(item => !item.deleted).map((item, idx) => 
                                        <div className={`usersetting-cards-item ${this.state.activeTab === idx ? ' active':''}`} key={idx} idx={idx}>
                                            <label className='usersetting-cards-item-label' onClick={() => this.handleTabChange(idx)}>
                                                { item.invalid && <FaExclamation className='usersetting-cards-item-invalid' /> }

                                                <div className='usersetting-cards-item-label-factors'>
                                                {
                                                    item.ao_m_factor === EnumMajorFactor.Card ? 
                                                        (item.c_code === EnumCodeFormat.Passcode.code_value ? <SVGIcon.PassCode className='symbol' /> : <SVGIcon.FactorCard className='symbol' />) :
                                                    item.ao_m_factor === EnumMajorFactor.Vehicle ? <SVGIcon.FactorVehicle className='symbol' /> :
                                                    item.ao_m_factor === EnumMajorFactor.Face ? <SVGIcon.FactorFace className='symbol' /> :
                                                    null
                                                }
                                                {
                                                    item.pin_enable &&
                                                    <Tooltip title={<FormattedMessage id='card_pin_code' />}>
                                                        <SVGIcon.PinCode/>
                                                    </Tooltip>
                                                }
                                                {
                                                    item.c_enable && item.ao_m_factor !== EnumMajorFactor.Card && 
                                                    <Tooltip title={item.c_code === EnumCodeFormat.Passcode.code_value ? <FormattedMessage id='passcode' /> : item.c_no}>
                                                        <SVGIcon.FactorCard />
                                                    </Tooltip>
                                                }
                                                {
                                                    item.v_enable && item.ao_m_factor !== EnumMajorFactor.Vehicle &&
                                                    <Tooltip title={item.v_no}>
                                                        <SVGIcon.FactorVehicle/>
                                                    </Tooltip>
                                                }
                                                {
                                                    item.pwd_enable &&
                                                    <Tooltip title={<FormattedMessage id='password' />}>
                                                        <SVGIcon.Password/>
                                                    </Tooltip>
                                                }
                                                </div>
                                                {
                                                    item.ao_m_factor === EnumMajorFactor.Card ?
                                                    <div>{item.c_code === EnumCodeFormat.Passcode.code_value ? <FormattedMessage id='passcode' /> : item.c_no}</div> :
                                                    item.ao_m_factor === EnumMajorFactor.Vehicle ? <div>{item.v_no}</div> : null
                                                }
                                            </label>
                                            {
                                                item.deletable &&
                                                <span className='usersetting-cards-delete' onClick={(e) => this.handleTabDelete(e, idx)}>
                                                    <SVGIcon.DeleteCircle />
                                                </span>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            {
                                this.state.activeTab > -1 &&
                                <Button variant={this.state.showAdvanced ? 'primary' : 'secondary'} className='usersetting-cards-advanced' onClick={this.handleShowAdvancedClick}>
                                    <FormattedMessage id='advanced' />
                                </Button>
                            }
                        </Card.Header>

                        <Card.Body className={'cardsetting-panel ' + (this.state.activeTab > -1? '' : 'hidden')}>
                            <Carousel ref={this.carouselRef} dots={false} speed={this.state.carouselSpeed} infinite={false}
                                beforeChange={this.handleBeforeCarouselChange} afterChange={this.handleAfterCarouselChange}
                            >
                            {
                                this.state.accessObjs.map((accessObj, index) => (
                                    <AccessObjectSetting ref={ref => this.allAccessObjRef[index] = ref} key={index} index={index} showAdvanced={this.state.showAdvanced}
                                        active={index === this.state.activeTab && !this.state.carouselAniming} getContainer={this.getContainer}
                                        accessData={accessObj} onDataChange={(accessObjData) => this.handleAccessObjChange(index, accessObjData)}
                                    />
                                ))
                            }
                            </Carousel>
                        </Card.Body>
                    </Card>
                </div>

                <LoadingMask show={this.state.showLoading} />

                <ShowAlertMessage title={<FormattedMessage id="user_setting" defaultMessage="User Setting" />} msg={this.state.alertMsg.msg} show={this.state.alertMsg.show} 
                    type={this.state.alertMsg.type} onClose={this.state.alertMsg.onClose || this.handleCloseAlert} backdrop='static' getContainer={this.getContainer}
                />
            </Modal>
        );
    }
};
UserSettingModal.defaultProps = {
    u_user_id: 0,
    fromLog: null,
    onClose: function(userData) {},
    getContainer: null
};

function UserPhotoUpload({u_user_id, ...props}) {
    const { ajaxASCloud } = useContext(AppContext);
    const fileInputRef = useRef(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [editPhoto, setEditPhoto] = useState(null);   // {file, imgSrc}
    const [alertMsg, setAlertMsg] = useState({show: false, msg: '', type: '', onClose: null});
    const isAjaxPhotoRef = useRef(false);

    const handlePhotoDrop = (files, e) => {
        if (props.disabled) return;

        const fnReadFile = file => {
            if (file.type.includes('image')) {
                var reader = new FileReader();
                reader.onload = function(event){
                    if (reader.result.includes('data:image')) {
                        getJpegBase64String(reader.result, props.onChange);
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        for (var i = 0; i < files.length; i++) {
            fnReadFile(files[i]);
        }
        setIsDragOver(false);
    };

    const handleTargetClick = () => {
        if (props.disabled) return;
        if (props.imgSrc) {
            isAjaxPhotoRef.current = false;
            setEditPhoto({imgSrc : props.imgSrc});
        } else {
            fileInputRef.current.click();
        }
        setIsDragOver(false);
    };

    const handleFileInputChange = (event) => {
        if (props.disabled) return;
        const { files } = event.target;
        handlePhotoDrop(files);
        fileInputRef.current.value = '';
    };

    const handleDelete = e => {
        if (props.disabled) return;
        e.stopPropagation();
        props.onChange(null, null);
        setIsDragOver(false);
    };

    const handlePhotoChange = ({u_photo, u_photo_s}) => {
        props.onChange(u_photo, u_photo_s);
        setEditPhoto(null);
    };

    const handlePhotoError = () => {
        if (isAjaxPhotoRef.current || u_user_id <= 0) {
            setAlertMsg({show: true, msg: <FormattedMessage id='invalid_data' />, type: 'error'});
            setEditPhoto(null);
        } else {
            ajaxASCloud(Constants.urls.getImage, {
                img_type: 0,
                img_size: 1,
                u_id: u_user_id
            }, (resp) => {
                if (!resp.errcode && Array.isArray(resp.data) && resp.data.length > 0) {
                    isAjaxPhotoRef.current = true;
                    setEditPhoto({imgSrc : resp.data[0].u_photo});
                } else {
                    setAlertMsg({show: true, msg: <FormattedMessage id='invalid_data' />, type: 'error'});
                    setEditPhoto(null);
                }
            });
        }
    };

    return (
        <>
            <FileDrop targetClassName={`usersetting-photo-upload noselect ${props.imgSrc ? 'uploaded' : ''} ${props.disabled ? 'disabled' : ''}`}
                onDragOver={e => setIsDragOver(true)} onDragLeave={e => setIsDragOver(false)}
                onTargetClick={handleTargetClick}
                onDrop={handlePhotoDrop}
            >
                {
                    props.imgSrc ?
                    <div className='img-wrap'>
                        <img src={props.imgSrc} alt='' crossOrigin="anonymous" />
                        {
                            !props.disabled &&
                            <div className='img-wrap-buttons'>
                                <SVGIcon.DeleteCircle onClick={handleDelete} />
                            </div>
                        }
                    </div> 
                    :
                    <span>{isDragOver ? <FormattedMessage id="drop_here" defaultMessage="Drop Here" /> : <FormattedMessage id="upload_drop_photo" defaultMessage="Upload or drop a photo" />}</span>
                }

                <input ref={fileInputRef} onChange={handleFileInputChange} type="file" accept="image/*"  />
            </FileDrop>
            {
                editPhoto &&
                <Suspense fallback={<LoadingMask show={true} />}>
                    <EditPhoto show={true} showUpload={true} {...editPhoto} onSave={handlePhotoChange} onError={handlePhotoError} onClose={() => setEditPhoto(null)} />
                </Suspense>
            }

            <ShowAlertMessage title={<FormattedMessage id="edit_photo" />} {...alertMsg} backdrop='static' onClose={() => {setAlertMsg({show: false, msg: '', type: ''})}} />
        </>
    );
}
UserPhotoUpload.defaultProps = {
    u_user_id: 0,
    imgSrc: null,
    disabled: false,
    onChange: function(base64Image, base64Image_s) {}
};

export const regexGeo64 = new RegExp('^([0-9A-Fa-f]{4}[ ]){3}([0-9A-Fa-f]{4})$');
export const regexVisitor = new RegExp('^([fF]{2})([0-9A-Fa-f]{2})[ ]([0-9A-Fa-f]{4}[ ]){2}([0-9A-Fa-f]{2})([fF]{2})$');
const CardNoField = ({disabled, getContainer, ...props}) => {
    const intl = useIntl();
    const ASConfig = useASConfig();
    const form = Form.useFormInstance();
    const c_code = Form.useWatch('c_code', form);
    const cardNoFieldRef = useRef();
    const isComposingRef = useRef(null);

    const codeFormat = useMemo(() => {
        var codeForamt = {...EnumCodeFormat.Wiegand26};
        Object.keys(EnumCodeFormat).some(key => {
            if (EnumCodeFormat[key].code_value === c_code) {
                codeForamt = {...EnumCodeFormat[key]};
                return true;
            }
            return false;
        });
        return codeForamt;
    }, [c_code]);
    
    const inputMask = useMemo(() => {
        var strMask = '';
        if (codeFormat.is_geo64) {
            strMask = '**** **** **** ****';
        } else {
            Array.from(Array(codeFormat.code_A_strlen).keys()).forEach(() => {
                strMask += '9';
            });
            if (codeFormat.code_A_strlen > 0 && codeFormat.code_B_strlen > 0) {
                strMask += '-';
            }
            Array.from(Array(codeFormat.code_B_strlen).keys()).forEach(() => {
                strMask += '9';
            });
        }
        
        return strMask;
    }, [codeFormat]);

    const cardFormatValidator = useMemo(() => {
        const haveDash = () => {
            return codeFormat.code_A_strlen > 0 && codeFormat.code_B_strlen > 0;
        };
    
        const splitCardNumber = (value) => {
            var code_a = value.substr(0, codeFormat.code_A_strlen);
            var dashLength = haveDash() ? 1 : 0;
            var code_b = value.substr(codeFormat.code_A_strlen + dashLength, codeFormat.code_B_strlen);
            return [code_a, code_b];
        };

        return () => ({
            validator(_, value) {
                if (disabled || !value) return Promise.resolve();

                var valid = false, errmsg;
                if (codeFormat.is_geo64) {
                    if (regexGeo64.test(value)) {
                        if (regexVisitor.test(value)) {
                            return Promise.reject(new Error(intl.formatMessage({id: 'card_number_visitor_format'})));
                        } else {
                            return Promise.resolve();
                        }
                    } else {
                        errmsg = intl.formatMessage({id: 'card_number_range_format', defaultMessage: 'The range of Card Number is between {0}{4}{1} and {2}{4}{3}'}, {
                            '0': '',
                            '1': '0000 0000 0000 0000',
                            '2': '',
                            '3': 'FFFF FFFF FFFF FFFF',
                            '4': ''
                        });
                        return Promise.reject(new Error(errmsg));
                    }
                } else {
                    if (value.length === inputMask.length) {
                        var [code_a, code_b] = splitCardNumber(value);
                        var code_a_val = parseInt(code_a),
                            code_b_val = parseInt(code_b);
                        
                        if (codeFormat.code_A_strlen > 0 && isFinite(code_a) && !isNaN(code_a_val) && code_a_val <= codeFormat.code_A_max && 
                            codeFormat.code_B_strlen > 0 && isFinite(code_b) && !isNaN(code_b_val) && code_b_val <= codeFormat.code_B_max) {
                            valid = true;
                        }
                    }
    
                    if (valid) {
                        return Promise.resolve();
                    } else {
                        errmsg = intl.formatMessage({id: 'card_number_range_format', defaultMessage: 'The range of Card Number is between {0}{4}{1} and {2}{4}{3}'}, {
                            '0': ''.padStart(codeFormat.code_A_strlen, '0'),
                            '1': ''.padStart(codeFormat.code_B_strlen, '0'),
                            '2': codeFormat.code_A_max,
                            '3': codeFormat.code_B_max,
                            '4': haveDash() ? '-' : ''
                        });
                        return Promise.reject(new Error(errmsg));
                    }
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeFormat, inputMask, disabled]);

    const passcodeValidator = useMemo(() => ({
        validator(_, value) {
            if (disabled || !value) return Promise.resolve();

            if (value && (value.includes('*') || (value.length >= 5 && value.length <= 8))) return Promise.resolve();
            else return Promise.reject(new Error(intl.formatMessage({id: '5_8_digits'})));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [disabled]);

    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        if (isComposingRef.current) {   // 預防使用注音輸入法時用keynum輸入會產生連字
            return oldState;
        }

        var { value, selection } = newState;

        value = value.toUpperCase();

        return {
            value,
            selection
        };
    };

    const handleCodeChange = (val) => {
        form.setFieldsValue({
            c_code: val,
            c_no: '',
            c_passcode: ''
        });

        setTimeout(() => {
            if (cardNoFieldRef.current) {
                cardNoFieldRef.current.focus();
            }
        }, 100);
    };

    const handleInput = (e) => {
        isComposingRef.current = e.nativeEvent.isComposing;
    };

    return (
        <Space.Compact block={true}>
            <Form.Item name='c_code' noStyle>
                <Select className='as-ant-select' disabled={disabled} onChange={handleCodeChange} getPopupContainer={getContainer}>
                {
                    Object.values(EnumCodeFormat).map((item) =>(
                        <Select.Option key={item.code_value} value={item.code_value}>{ASConfig.getCardCodeName(item.code_value)}</Select.Option>    
                    ))
                }
                </Select>
            </Form.Item>
            {
                c_code === EnumCodeFormat.Passcode.code_value ?
                <Form.Item name='c_passcode' noStyle rules={[passcodeValidator]}>
                    <NumberCodeField ref={cardNoFieldRef} type={enumNumberCodeType.CommonPassword} placeholder={intl.formatMessage({id: '5_8_digits'})} disabled={disabled} />    
                </Form.Item>
                :
                <Form.Item name='c_no' noStyle rules={[cardFormatValidator]}>
                    <InputMask formatChars={{'*': '[A-Fa-f0-9]', '9': '[0-9]'}} mask={inputMask} disabled={disabled} beforeMaskedValueChange={beforeMaskedValueChange}
                        className={`${codeFormat.is_geo64 ? 'geo64-font' : ''}`} onInput={handleInput}
                    >
                    {
                        (inputProps) => <Input ref={cardNoFieldRef} {...inputProps} disabled={disabled} />
                    }
                    </InputMask>
                </Form.Item>
            }
        </Space.Compact>
    );
};
CardNoField.defaultProps = {
    disabled: false,
    getContainer: null
};

const AccessRuleField = ({getContainer, hideLabel, disabled}) => {
    const { getRegionList, getAccessRuleList, regionList, accessRuleList, setAccessRuleList, accountInfo } = useContext(AppContext);
    const form = Form.useFormInstance();
    const ar_ids = Form.useWatch('ar_ids', form);

    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {getRegionList.apply(null)}, [getRegionList]);
    useEffect(() => {getAccessRuleList.apply(null)}, [getAccessRuleList]);

    const handleChange = (e) => {
        setTimeout(() => {
            form.validateFields(['ar_ids']);
        }, 1);
    };

    const handleAddAccessRuleSave = accessRule => {
        var ids = [...ar_ids, accessRule.ar_id];
        ids.sort();
        form.setFieldValue('ar_ids', ids);
        
        setAccessRuleList([...accessRuleList, accessRule]);
        setShowAddModal(false);
    };

    return (
        <div className='usersetting-accessrule-row'>
            <Form.Item name='ar_ids' label={hideLabel ? null : <FormattedMessage id="access_rule" defaultMessage="Access Rule" />}
                rules={disabled ? [] : [
                    {type: 'array', required: true }, 
                    {type: 'array', max: Constants.maxCardsAccessRuleCount, message: <FormattedMessage id="maximum_access_rules" defaultMessage="Exceeded the maximum number of Access Rules." />}
                ]}
            >
                <Select mode="multiple" placeholder={<FormattedMessage id="search_access_rule" defaultMessage="Search Access Rule" />} 
                    showSearch={true} notFoundContent={<NoDataMask />} disabled={disabled}
                    allowClear={Array.isArray(ar_ids) && !ar_ids.some(ar_id => accessRuleList.some(item => item.ar_id === ar_id && !item.privilege.includes(writePriv)))}
                    className='as-ant-select' optionFilterProp="children" getPopupContainer={getContainer}
                    onChange={handleChange}
                >
                {
                    regionList.map(region => {
                        var list = accessRuleList.filter(item => item.rg_id === region.rg_id);
                        if (list.length > 0) {
                            return (
                                <Select.OptGroup key={region.rg_id} label={region.rg_name}>
                                {
                                    list.map(item => (
                                        <Select.Option key={item.ar_id} value={item.ar_id} disabled={!item.privilege.includes(writePriv)} className='usersetting-mulitselect-option'>
                                            {item.ar_name}
                                        </Select.Option>
                                    ))
                                }
                                </Select.OptGroup> 
                            )
                        }
                        return null;
                    })
                }
                </Select>
            </Form.Item>
            {
                accountInfo.type !== EnumAccountType.user && !hideLabel && !disabled &&
                <Tooltip placement='bottom' title={<FormattedMessage id="add_access_rule" defaultMessage="Add Access Rule" />}>
                    <div className='usersetting-add-accessrule' onClick={() => setShowAddModal(true)}>
                        <FaPlusCircle className='icon-btn'/>
                    </div>
                </Tooltip>
            }

            <Suspense fallback={<LoadingMask show={true} />}>
                <AccessRuleModal getContainer={getContainer}
                    show={showAddModal}
                    onCancel={() => setShowAddModal(false)}
                    onSave={handleAddAccessRuleSave}
                />
            </Suspense>
        </div>
    );
};
AccessRuleField.defaultProps = {
    getContainer: null,
    hideLabel: false,
    disabled: false
};

const AccessObjectSetting = forwardRef(({index, active, getContainer, accessData, showAdvanced, onDataChange, ...props}, ref) => {
    const intl = useIntl();
    const ASConfig = useASConfig();
    const [form] = Form.useForm();
    const c_code = Form.useWatch('c_code', form);
    const c_enable = Form.useWatch('c_enable', form);
    const v_enable = Form.useWatch('v_enable', form);
    const pin_enable = Form.useWatch('pin_enable', form);
    const pwd_enable = Form.useWatch('pwd_enable', form);

    const formName = useMemo(() => {
        return `accesskey_form_${index}`;
    }, [index]);

    const validateMessages = {
        required: intl.formatMessage({id: 'required_field'}),
        string: {
            /* eslint-disable no-template-curly-in-string */
            max: intl.formatMessage({id: 'maximum_field_length'}, {'0': '${max}'})
        }
    };

    useEffect(() => {
        form.setFieldsValue(accessData);
    }, [form, accessData]);

    const focusField = (key) => {
        if (!active) return;
        var field = form.getFieldInstance(key);
        if (field && field.focus) {
            field.focus();
        } else if (field && field.getInputDOMNode) {
            var el = field.getInputDOMNode();
            if (el && el.focus) {
                el.focus();
            }
        }
    };

    const handleDataChange = (datas) => {
        onDataChange({
            ...accessData,
            ...datas
        });
    };

    const handleFormChange = (changedValues, allValues) => {
        if (typeof(changedValues.c_code) !== 'undefined') {
            allValues.c_no = '';
            if (changedValues.c_code === EnumCodeFormat.Passcode.code_value) {
                allValues.pin_enable = false;
            }
        }
        handleDataChange(allValues);
        Object.entries(changedValues).forEach(([key, value]) => {
            if (key === 'c_enable' || key === 'v_enable' || key === 'pin_enable' || key === 'pwd_enable') {
                var field = key === 'v_enable' ? 'v_no' :
                            key === 'pin_enable' ? 'c_pin_code' :
                            key === 'pwd_enable' ? 'pwd' :
                            c_code === EnumCodeFormat.Passcode.code_value ? 'c_passcode' : 'c_no';
                if (value) {
                    setTimeout(() => {
                        focusField(field);    
                    }, 300);
                } else {
                    form.validateFields([field]);
                }
            }
        });
    };

    const handleValuesChange = (values) => {
        handleDataChange({
            ...form.getFieldsValue(),
            ...values
        });
    };

    useImperativeHandle(ref, () => ({
        validateFields(focus, callback) {
            callback = callback || function() {};

            form.validateFields()
            .then((values) => {
                callback(accessData.c_enable || accessData.v_enable || accessData.f_enable);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
                if (focus && Array.isArray(info.errorFields) && info.errorFields.length > 0) {
                    info.errorFields.some(item => {
                        if (Array.isArray(item.name) && item.name.length > 0) {
                            focusField(item.name[0]);
                            return true;
                        }
                        return false;
                    });
                }
                callback(false);
            });
        }
    }));

    return (
        <div className={`access-obj-panel`}>
            {
                accessData.readOnly &&
                <h4 className='access-obj-readonly'><Badge bg="secondary"><FormattedMessage id='read_only' /></Badge></h4>
            }
            <Form form={form} name={formName} className='access-obj-form' colon={false} autoComplete="off" disabled={accessData.readOnly}
                validateMessages={validateMessages} onValuesChange={handleFormChange} labelWrap
            >
                <Row>
                {
                    accessData.ao_m_factor === EnumMajorFactor.Card &&
                    <Col md="6">
                        <Form.Item htmlFor={c_code === EnumCodeFormat.Passcode.code_value ? `${formName}_c_passcode` : `${formName}_c_no`} 
                            name={c_code === EnumCodeFormat.Passcode.code_value ? 'c_passcode' : 'c_no'} rules={[{required: true}]}
                            label={<FormattedMessage id="card_number" defaultMessage="Card Number" />} className='cardsetting-form-card-no'
                        >
                            <CardNoField getContainer={getContainer} disabled={accessData.readOnly} />
                        </Form.Item>
                    </Col>
                }
                {
                    accessData.ao_m_factor === EnumMajorFactor.Vehicle &&
                    <Col md="6">
                        <Form.Item name='v_no' label={<FormattedMessage id="license_plate" defaultMessage="License Plate" />} rules={[{required: true}, {type: 'string', max: enumAccesskeyFieldsMaxLength.v_no}]}>
                            <Input disabled={accessData.readOnly} allowClear={true} />
                        </Form.Item>
                    </Col>
                }
                    <Col md="6">
                        <Form.Item name='ao_active' label={<FormattedMessage id="status" defaultMessage="Status" />}>
                            <Select className='as-ant-select' getPopupContainer={getContainer}>
                            {
                                Object.values(EnumCardStatus).map((val) => (
                                    <Select.Option key={val} value={val}>{ASConfig.getCardStatusName(val)}</Select.Option>    
                                ))
                            }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <ASActiveDateRange
                    startPlaceholder={intl.formatMessage({id: 'activation_date'})} endPlaceholder={intl.formatMessage({id: 'deactivation_date'})}
                    startDate={accessData.ao_start_time} endDate={accessData.ao_end_time}
                    startFieldId={`${formName}_activation_date`} endFieldId={`${formName}_deactivation_date`}
                    disabled={accessData.readOnly} getPopupContainer={getContainer} onChange={handleValuesChange}
                >
                {
                    (startField, endField) => (
                        <Row>
                            <Col md="6">
                                <Form.Item htmlFor={`${formName}_activation_date`} label={<FormattedMessage id="activation_date" defaultMessage="Activation Date" />}>
                                    {startField}
                                </Form.Item>
                            </Col>
                            <Col md="6">
                                <Form.Item htmlFor={`${formName}_deactivation_date`} label={<FormattedMessage id="deactivation_date" defaultMessage="Deactivation Date" />}>
                                    {endField}
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }
                </ASActiveDateRange>
                {
                    accessData.ao_m_factor === EnumMajorFactor.Vehicle &&
                    <Row>
                        <Col md="6">
                            <Form.Item name='v_classification' label={<FormattedMessage id="classification" defaultMessage="Classification" />} rules={[{type: 'string', max: enumAccesskeyFieldsMaxLength.v_classification}]}>
                                <Input disabled={accessData.readOnly} allowClear={true} />
                            </Form.Item>
                        </Col>
                        <Col md="6">
                            <Form.Item name='v_color' label={<FormattedMessage id="color" defaultMessage="Color" />} rules={[{type: 'string', max: enumAccesskeyFieldsMaxLength.v_color}]}>
                                <Input disabled={accessData.readOnly} allowClear={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col md="12">
                        <AccessRuleField getContainer={getContainer} disabled={accessData.readOnly} />
                    </Col>
                </Row>
                <Row>
                    {
                        showAdvanced && accessData.ao_m_factor === EnumMajorFactor.Card &&
                        <Col md="6" className='access-obj-subfactor-col'>
                            <Form.Item name='pin_enable' valuePropName='checked'>
                                <Checkbox disabled={accessData.readOnly || c_code === EnumCodeFormat.Passcode.code_value}>
                                    <FormattedMessage id="card_pin_code" defaultMessage="Pin Code" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item name='c_pin_code'
                                rules={c_code !== EnumCodeFormat.Passcode.code_value ? 
                                    [{required: pin_enable}, {type: 'string', len: 4, message: <FormattedMessage id="card_pin_code_invalid_msg" defaultMessage="Pin Code must contain 4 numbers." />}] : 
                                    []
                                }
                            >
                                <NumberCodeField type={enumNumberCodeType.PinCode} placeholder={intl.formatMessage({id: '4_digits'})} 
                                    disabled={accessData.readOnly || c_code === EnumCodeFormat.Passcode.code_value || !pin_enable}
                                />
                            </Form.Item>
                        </Col>
                    }
                    {
                        showAdvanced &&accessData.ao_m_factor !== EnumMajorFactor.Card &&
                        <Col md="6" className='access-obj-subfactor-col'>
                            <Form.Item name='c_enable' valuePropName='checked'>
                                <Checkbox disabled={accessData.readOnly}><FormattedMessage id="card_number" defaultMessage="Card Number" /></Checkbox>
                            </Form.Item>
                            <Form.Item name={c_code === EnumCodeFormat.Passcode.code_value ? 'c_passcode' : 'c_no'} rules={[{required: c_enable}]}
                                className='cardsetting-form-card-no'
                            >
                                <CardNoField getContainer={getContainer} disabled={accessData.readOnly || !c_enable} />
                            </Form.Item>
                        </Col>
                    }
                    {
                        showAdvanced && accessData.ao_m_factor !== EnumMajorFactor.Vehicle && process.env.REACT_APP_ENABLE_VEHICLE &&
                        <Col md="6" className='access-obj-subfactor-col'>
                            <Form.Item name='v_enable' valuePropName='checked'>
                                <Checkbox disabled={accessData.readOnly}><FormattedMessage id="license_plate" defaultMessage="License Plate" /></Checkbox>
                            </Form.Item>
                            <Form.Item name='v_no' rules={[{required: v_enable}, {type: 'string', max: enumAccesskeyFieldsMaxLength.v_no}]}>
                                <Input disabled={accessData.readOnly || !v_enable} placeholder={intl.formatMessage({id: 'license_plate'})} />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                {
                    showAdvanced && accessData.ao_m_factor === EnumMajorFactor.Face && 
                    <Row>
                        <Col md="6" className='access-obj-subfactor-col'>
                            <Form.Item name='pwd_enable' valuePropName='checked'>
                                <Checkbox disabled={accessData.readOnly}><FormattedMessage id="password" defaultMessage="Password" /></Checkbox>
                            </Form.Item>
                            <Form.Item name='pwd' rules={[{required: pwd_enable}, {type: 'string', max: 8, min: 4, message: intl.formatMessage({id: '4_8_digits'})}]}>
                                <NumberCodeField type={enumNumberCodeType.PinCode}
                                    disabled={accessData.readOnly || !pwd_enable} placeholder={intl.formatMessage({id: '4_8_digits'})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </div>
    );
});
AccessObjectSetting.defaultProps = {
    index: -1,
    active: false,  // after animation
    getContainer: null,
    accessData: {...defaultAccessObjData},
    showAdvanced: false,
    onDataChange: function() {}
};

export const BatchEditModal = ({users, onClose}) => {
    const intl = useIntl();
    const ASConfig = useASConfig();
    const { organizationMap, organizationTitles, getOrganizationMap, accountInfo, ajaxASCloud } = useContext(AppContext);
    const [open, setOpen] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState({show: false, msg: '', type: '', onClose: null});
    const [form] = Form.useForm();
    const chk_org = Form.useWatch('chk_org', form);
    const chk_title = Form.useWatch('chk_title', form);
    const chk_memo = Form.useWatch('chk_memo', form);
    const chk_start_time = Form.useWatch('chk_start_time', form);
    const chk_end_time = Form.useWatch('chk_end_time', form);
    const chk_active = Form.useWatch('chk_active', form);
    const chk_ars = Form.useWatch('chk_ars', form);
    const chk_classification = Form.useWatch('chk_classification', form);
    const chk_color = Form.useWatch('chk_color', form);

    const u_og1_id = Form.useWatch('u_og1_id', form);
    const u_og2_id = Form.useWatch('u_og2_id', form);
    const ao_start_time = Form.useWatch('ao_start_time', form);
    const ao_end_time = Form.useWatch('ao_end_time', form);

    const validateMessages = {
        required: intl.formatMessage({id: 'required_field'}),
        string: {
            /* eslint-disable no-template-curly-in-string */
            max: intl.formatMessage({id: 'maximum_field_length'}, {'0': '${max}'})
        }
    };

    useEffect(getOrganizationMap, [getOrganizationMap]);

    const listOg1 = useMemo(() => {
        var options = [{label: ' ', value: 0}];
        organizationMap.forEach(item => {
            if (!item.og_name) return;
            options.push({
                label: item.og_name,
                value: item.og_id
            });
        });
        return options;
    }, [organizationMap]);

    const listOg2 = useMemo(() => {
        var options = [{label: ' ', value: 0}];
        const org1 = organizationMap.find(item => item.og_id === u_og1_id);
        if (org1 && Array.isArray(org1.children)) {
            org1.children.forEach(item => {
                options.push({
                    label: item.og_name || ' ',
                    value: item.og_id
                });
            });
        }
        return options;
    }, [organizationMap, u_og1_id]);

    const listOg3 = useMemo(() => {
        var options = [{label: ' ', value: 0}];
        const org1 = organizationMap.find(item => item.og_id === u_og1_id);
        if (org1 && Array.isArray(org1.children)) {
            const org2 = org1.children.find(item => item.og_id === u_og2_id);
            if (org2 && Array.isArray(org2.children)) {
                org2.children.forEach(item => {
                    options.push({
                        label: item.og_name || ' ',
                        value: item.og_id
                    });
                });
            }
        }
        return options;
    }, [organizationMap, u_og1_id, u_og2_id]);

    const dateFormat = useMemo(() => {
        return accountInfo?.time_format.toString().split(' ')?.[0] || 'MM-DD-YYYY';
    }, [accountInfo.time_format]);

    const getContainer = () => {
        return document.querySelector('.batch-user-form');
    };

    const focusField = (key) => {
        var field = form.getFieldInstance(key);
        if (field && field.focus) {
            field.focus();
        } else if (field && field.getInputDOMNode) {
            var el = field.getInputDOMNode();
            if (el && el.focus) {
                el.focus();
            }
        }
    };

    const handleFormChange = (changedValues, allValues) => {
        Object.entries(changedValues).forEach(([key, value]) => {
            if (key.includes('chk_')) {
                var fieldName;
                switch(key) {
                    case 'chk_org': fieldName = 'u_og1_id'; break;
                    case 'chk_title': fieldName = 'u_title'; break;
                    case 'chk_memo': fieldName = 'u_memo'; break;
                    case 'chk_start_time': fieldName = 'ao_start_time'; break;
                    case 'chk_end_time': fieldName = 'ao_end_time'; break;
                    case 'chk_active': fieldName = 'ao_active'; break;
                    case 'chk_ars': fieldName = 'ar_ids'; break;
                    case 'chk_classification': fieldName = 'v_classification'; break;
                    case 'chk_color': fieldName = 'v_color'; break;
                    default: break;
                }
                if (!fieldName) return;

                if (value) {    // focus field
                    setTimeout(() => {
                        focusField(fieldName);    
                    }, 100);
                } else {
                    form.validateFields([fieldName]);
                }
            } else if (key === 'u_og1_id') {
                form.setFieldsValue({u_og2_id: 0, u_og3_id: 0});
            } else if (key === 'u_og2_id') {
                form.setFieldsValue({u_og3_id: 0});
            }
        });
    };

    const handleOk = () => {
        form.validateFields()
        .then((values) => {
            setShowLoading(true);
            var params = {
                api_cmd: EnumASCloudAPIType.BATCH_USER,
                action_type: EnumActionType.Edit,
                u_user_ids: [],
                is_notify: true     // Notify all browser to update data (for separate batch upload)
            };
            users.forEach(user => params.u_user_ids.push(user.u_user_id));
            Object.entries(values).forEach(([key, value]) => {
                if (key.includes('chk_')) return;

                if (key === 'ar_ids') {
                    params[key] = values.chk_ars ? value : [];
                } else if (key === 'ao_start_time' || key === 'ao_end_time') {
                    params[key] = value ? moment(value, `${dateFormat} HH:mm`).format('YYYY-MM-DD HH:mm') : '';
                } else if (key.includes('u_og')) {
                    params[key] = value ? value : 0;
                } else {
                    params[key] = value;
                }
            });
            
            ajaxASCloud(Constants.urls.access, params, (resp) => {
                var _alertMsg = {
                    show: true,
                    type: resp.errcode ? 'error' : 'success',
                    msg: resp.errcode ? resp.errmsg : <FormattedMessage id="saved_successfully" defaultMessage="Saved successfully." />,
                    onClose: null
                };
                if (!resp.errcode) {
                    _alertMsg.onClose = () => {
                        setOpen(false);
                        setTimeout(onClose, 300);
                    };
                } else {
                    if (Array.isArray(resp.data?.u_user_ids)) {
                        var errUsers = [];
                        resp.data.u_user_ids.forEach((u_user_id) => {
                            var user = users.find(user => user.u_user_id === u_user_id);
                            if (user) {
                                errUsers.push(user.u_name);
                            }
                        });
                        _alertMsg.msg += `<br/>${intl.formatMessage({id: 'user_list'})}: ${errUsers.join(', ')}`;
                    }
                }
                setAlertMsg(_alertMsg);
                setShowLoading(false);
            });
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
            if (Array.isArray(info.errorFields) && info.errorFields.length > 0) {
                info.errorFields.some(item => {
                    if (Array.isArray(item.name) && item.name.length > 0) {
                        focusField(item.name[0]);
                        return true;
                    }
                    return false;
                });
            }
        });
    };

    return (
        <Modal centered width={1000} className='batch-user-modal' open={open} onCancel={() => {setOpen(false); setTimeout(onClose, 300);}}
            title={<FormattedMessage id='batch_edit' />} okText={<FormattedMessage id ='save' />}
            okButtonProps={{disabled: !chk_org && !chk_title && !chk_memo && !chk_start_time && !chk_end_time && !chk_active && !chk_ars && !chk_classification && !chk_color}}
            onOk={handleOk}
        >
            <Card className='batch-user-list'>
                <Card.Header>
                    <FormattedMessage id='user_list' />
                </Card.Header>
                <ListGroup variant="flush" className='scroll-box'>
                {
                    users.map((user) => (
                        <ListGroup.Item key={user.u_user_id}>
                            <div className='gv-user-avatar'>
                            {
                                user.u_photo_s &&
                                <ASImage log_type='user' u_user_id={user.u_user_id} src={user.u_photo_s} onLoad={e => e.target.classList.add('loaded')} />
                            }
                            </div>
                            <span>{user.u_name}</span>
                        </ListGroup.Item>
                    ))
                }
                </ListGroup>
                <Card.Footer>
                    <FormattedMessage id="total" />:&nbsp;&nbsp;{users.length}
                </Card.Footer>
            </Card>
            <Form form={form} className='batch-user-form scroll-box' layout="vertical" autoComplete="off" initialValues={{...defaultUserData, ...defaultAccessObjData}}
                validateMessages={validateMessages} onValuesChange={handleFormChange}
            >
                <div style={{marginRight: '12px'}}>
                    <Row className='batch-user-form-chk'>
                        <Col md="12">
                            <Form.Item name='chk_org' valuePropName='checked'>
                                <Checkbox><FormattedMessage id='organization' /></Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='batch-user-form-content batch-user-organization'>
                        <Col md="4">
                            <Form.Item name={chk_org ? 'u_og1_id' : ''} label={<><SVGIcon.OrganizationLvl1 />{organizationTitles[0] || <FormattedMessage id='division' />}</>}>
                                <Select className='as-ant-select' disabled={!chk_org} getPopupContainer={getContainer} options={listOg1} />
                            </Form.Item>
                        </Col>
                        <Col md="4">
                            <Form.Item name={chk_org ? 'u_og2_id' : ''} label={<><SVGIcon.OrganizationLvl2 />{organizationTitles[1] || <FormattedMessage id='department' />}</>}>
                                <Select className='as-ant-select' disabled={!chk_org} getPopupContainer={getContainer} options={listOg2} />
                            </Form.Item>
                        </Col>
                        <Col md="4">
                            <Form.Item name={chk_org ? 'u_og3_id' : ''} label={<><SVGIcon.OrganizationLvl3 />{organizationTitles[2] || <FormattedMessage id='sector' />}</>}>
                                <Select className='as-ant-select' disabled={!chk_org} getPopupContainer={getContainer} options={listOg3} />
                            </Form.Item>
                        </Col>
                    </Row>
            
                    <Row>
                        <Col md="4">
                            <Row className='batch-user-form-chk'>
                                <Col md="12">
                                    <Form.Item name='chk_title' valuePropName='checked'>
                                        <Checkbox><FormattedMessage id='job_title' /></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='batch-user-form-content'>
                                <Col md="12">
                                    <Form.Item name={chk_title ? 'u_title' : ''} rules={chk_title ? [{type: 'string', max: 16}] : []}>
                                        <Input disabled={!chk_title} allowClear={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="8">
                            <Row className='batch-user-form-chk'>
                                <Col md="12">
                                    <Form.Item name='chk_memo' valuePropName='checked'>
                                        <Checkbox><FormattedMessage id='memo' /></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='batch-user-form-content'>
                                <Col md="12">
                                    <Form.Item name={chk_memo ? 'u_memo' : ''}>
                                        <Input disabled={!chk_memo} allowClear={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                
                    <div className='batch-user-form-divider'><span><FormattedMessage id='access_object' /></span></div>

                    <Row>
                        <Col md="6">
                            <Row className='batch-user-form-chk'>
                                <Col md="12">
                                    <Form.Item name='chk_start_time' valuePropName='checked'>
                                        <Checkbox><FormattedMessage id='activation_date' /></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='batch-user-form-content'>
                                <Col md="12">
                                    <Form.Item name={chk_start_time ? 'ao_start_time' : ''}>
                                        <ASDateField dateFormat={dateFormat} timeFormat='HH:mm' allowClear={true} isStart={true} disabled={!chk_start_time}
                                            max={chk_end_time ? ao_end_time : ''} getPopupContainer={getContainer}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <Row className='batch-user-form-chk'>
                                <Col md="12">
                                    <Form.Item name='chk_end_time' valuePropName='checked'>
                                        <Checkbox><FormattedMessage id='deactivation_date' /></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='batch-user-form-content'>
                                <Col md="12">
                                    <Form.Item name={chk_end_time ? 'ao_end_time' : ''}>
                                        <ASDateField dateFormat={dateFormat} timeFormat='HH:mm' allowClear={true} disabled={!chk_end_time}
                                            min={chk_start_time ? ao_start_time : ''} getPopupContainer={getContainer}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Row className='batch-user-form-chk'>
                                <Col md="12">
                                    <Form.Item name='chk_active' valuePropName='checked'>
                                        <Checkbox><FormattedMessage id='status' /></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='batch-user-form-content'>
                                <Col md="12">
                                    <Form.Item name={chk_active ? 'ao_active' : ''}>
                                        <Select className='as-ant-select' disabled={!chk_active} getPopupContainer={getContainer}>
                                        {
                                            Object.values(EnumCardStatus).map((val) => (
                                                <Select.Option key={val} value={val}>{ASConfig.getCardStatusName(val)}</Select.Option>    
                                            ))
                                        }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="8">
                            <Row className='batch-user-form-chk'>
                                <Col md="12">
                                    <Form.Item name='chk_ars' valuePropName='checked'>
                                        <Checkbox><FormattedMessage id='access_rule' /></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='batch-user-form-content'>
                                <Col md="12">
                                    <AccessRuleField hideLabel={true} disabled={!chk_ars} getContainer={getContainer} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    {
                        !!process.env.REACT_APP_ENABLE_VEHICLE &&
                        <>
                            <div className='batch-user-form-divider'><span><FormattedMessage id='vehicle' /></span></div>

                            <Row>
                                <Col md="6">
                                    <Row className='batch-user-form-chk'>
                                        <Col md="12">
                                            <Form.Item name='chk_classification' valuePropName='checked'>
                                                <Checkbox><FormattedMessage id='classification' /></Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className='batch-user-form-content'>
                                        <Col md="12">
                                            <Form.Item name={chk_classification ? 'v_classification' : ''} rules={chk_classification ? [{type: 'string', max: 32}] : []}>
                                                <Input disabled={!chk_classification} allowClear={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6">
                                    <Row className='batch-user-form-chk'>
                                        <Col md="12">
                                            <Form.Item name='chk_color' valuePropName='checked'>
                                                <Checkbox><FormattedMessage id='color' /></Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className='batch-user-form-content'>
                                        <Col md="12">
                                            <Form.Item name={chk_color ? 'v_color' : ''} rules={chk_color ? [{type: 'string', max: 20}] : []}>
                                                <Input disabled={!chk_color} allowClear={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
                
            </Form>

            <LoadingMask show={showLoading} text='' />
            <ShowAlertMessage title={<FormattedMessage id="batch_edit" />} {...alertMsg} backdrop='static' getContainer={getContainer}
                onClose={alertMsg.onClose || (() => {setAlertMsg({show: false, msg: '', type: '', onClose: null})})} 
            />
        </Modal>
    );
};
BatchEditModal.defaultProps = {
    users: [],
    onClose: function() {}
};